import * as React from "react"
import { graphql } from "gatsby"

import { IContentNode } from "../types/Content"
import { ILocation } from "../types/Location"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ArticleTimeline, { makeArticleTimelineItem } from "../components/ArticleTimeline"
import PostHeader from "../components/PostHeader"

import "./styles/tag.scss"

interface IProps {
  location: ILocation
  data: {
    tag: {
      value: string
    }

    allTagContent: {
      edges: [
        {
          node: {
            content: IContentNode
          }
        }
      ]
      totalCount: number
    }
  }
}

const TagTemplate: React.FC<IProps> = ({ data, location }) => (
  <Layout currentPath={location.pathname}>
    {({ theme }) => (
      <>
        <SEO title={data.tag.value} />

        <PostHeader
          description={`${data.allTagContent.totalCount} publication(s) tagged with "${data.tag.value}"`}
          icon="🏷"
          theme={theme}
          title={data.tag.value}
        />

      <div className={`tag-articles--${theme}`}>
        <h1 className="tag-articles__header fs-32">📝 Articles</h1>

        <ArticleTimeline
          articles={data.allTagContent.edges.map(({ node }) => makeArticleTimelineItem(node.content))}
          currentPath={location.pathname}
          theme={theme}
          showItemCategory={true}
        />
      </div>
      </>
    )}
  </Layout>
)

export const query = graphql`
  query($tag: String!) {
    tag: tagJson(value: {eq: $tag}) {
      value
    }

    allTagContent: allFile(
      filter: { childrenMdx: {elemMatch: {frontmatter: {tags: {eq: $tag}}}}, sourceInstanceName: { in: ["articles"] } }
      sort: { fields: [childMdx___frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          content: childMdx {
            id
            body
            frontmatter {
              category
              date
              tags
              title
            }
            fields {
              slug
            }
          }
        }
      }
      totalCount
    }
  }
`

export default TagTemplate
